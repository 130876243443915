.menu-notify {
  position: absolute;
  width: 100%;
  right: 0px;
  top: 58px;
}
.menu-notify .cont-menu-notify {
  color: #595955;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.menu-notify .cont-menu-notify .title {
  padding: 8px 12px 6px;
  border-bottom: 1px solid #bdbdbd;
}
.menu-notify .cont-menu-notify .title span {
  font-size: 12px;
  font-weight: 600;
  color: #6857b2;
}
.menu-notify .cont-menu-notify .cont-info-notify {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.menu-notify .cont-menu-notify .cont-info-notify .info-notify {
  font-size: 13px;
  padding: 8px 12px;
  border-bottom: 1px solid #bdbdbd;
}
.menu-notify .cont-menu-notify .cont-info-notify .info-notify .fecha {
  font-size: 11px;
  color: #6857b2;
  text-align: right;
}
.menu-notify .cont-menu-notify .cont-info-notify .info-notify.sinleer {
  background-color: rgba(104,87,178,0.161);
}
@media screen and (min-width: 600px) {
  .menu-notify {
    width: 430px;
    right: 70px;
    top: 64px;
  }
}
