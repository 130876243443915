.manto-post {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1200;
  position: fixed;
  touch-action: none;
  background-color: rgba(0,0,0,0.5);
  -webkit-tap-highlight-color: transparent;
}
.oculto {
  display: none;
  transition: 5s all;
}
.cont-new-post {
  z-index: 1201;
  position: relative;
}
.cont-text-post {
  width: 100%;
}
.cont-text-post .text-post {
  padding: 4px 12px 0;
}
.action {
  padding: 12px 15px;
}
.imgPreviewPost {
  width: 120px;
  height: 120px;
  position: relative;
  margin-bottom: 1rem;
}
.imgPreviewPost img {
  width: 100%;
  height: 100%;
}
.imgPreviewPost .clear-img {
  right: 0;
  padding: 0;
  position: absolute;
}
.videoView {
  height: 0;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-bottom: 56.25% /* 16:9 Aspect Ratio */;
}
.videoView video {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.videoView .clear-video {
  right: 0;
  z-index: 5;
  position: absolute;
}
.previewTextPost {
  background-color: #e9ebee;
  min-height: 50px;
  position: relative;
}
.cargarImage {
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
}
.cont-list-post {
  width: 100%;
}
.post .header-post {
  display: flex;
  margin-bottom: 12px;
}
.post .header-post .link-epage {
  width: 100%;
  display: flex;
}
.post .header-post .autor-fech {
  width: 90%;
  padding-top: 5px;
  padding-left: 12px;
}
.post .header-post .autor-fech span {
  line-height: 1.3;
}
.post .header-post .autor-fech span:first-child {
  font-weight: bold;
}
.post .header-post .autor-fech span:last-child {
  font-size: 0.7rem;
}
.post .body-post .cont-text span {
  font-size: 1rem;
}
.post .body-post .cont-img-post {
  width: 100%;
  height: auto;
  text-align: center;
}
.post .body-post .cont-img-post img {
  width: 100%;
  height: auto;
}
.post .body-post .cont-video-post {
  height: 0;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-bottom: 56.25% /* 16:9 Aspect Ratio */;
}
.post .body-post .cont-video-post video {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.post .action-body {
  display: flex;
}
.post .coment-post .mb-12 {
  margin-bottom: 12px;
}
.post .coment-post .comentary .cont-comentary {
  padding: 6px 10px;
  border-radius: 18px;
  background-color: #edf0f1;
}
.post .coment-post .comentary .cont-comentary.editar-comentary {
  width: 94%;
  border: 1px solid #b4b9bb;
}
.post .coment-post .comentary .cont-comentary.editar-comentary .text-area {
  width: calc(100% - 35px);
}
.post .coment-post .comentary .cont-comentary.editar-comentary .icon-emoji {
  float: right;
}
.post .coment-post .comentary .cont-comentary.editar-comentary .icon-emoji .btn-icon-emoji {
  padding: 0;
  min-width: 30px;
}
.post .coment-post .comentary .cont-comentary.editar-comentary .icon-emoji .btn-icon-emoji:hover {
  background-color: transparent !important;
}
.post .coment-post .comentary .cont-comentary .autor-comentary {
  color: #6857b2;
  font-weight: 600;
  margin-right: 6px;
}
.post .coment-post .cont-reply-comment {
  margin-left: 42px;
  width: calc(100% - 42px);
}
.post .coment-post .cont-reply-comment .boton-action {
  padding: 0;
  min-height: auto;
  font-size: 0.8rem;
  margin-bottom: 6px;
}
.post .coment-post .cont-progress-comentary {
  width: 100%;
  height: 35px;
  text-align: center;
}
.post .coment-post .cont-new-comentary .cont-letteravatars {
  display: inline-block;
}
.post .coment-post .cont-new-comentary .cont-imput {
  padding: 6px 10px;
  border-radius: 18px;
  display: inline-block;
  width: calc(100% - 46px);
  border: 1px solid #b4b9bb;
  background-color: #edf0f1;
}
.post .coment-post .cont-new-comentary .cont-imput .text-area {
  width: calc(100% - 35px);
}
.post .coment-post .cont-new-comentary .cont-imput .icon-emoji {
  float: right;
}
.post .coment-post .cont-new-comentary .cont-imput .icon-emoji .btn-icon-emoji {
  padding: 0;
  min-width: 30px;
}
.post .coment-post .cont-new-comentary .cont-imput .icon-emoji .btn-icon-emoji:hover {
  background-color: transparent !important;
}
.post .coment-post .ver-respuestas {
  cursor: pointer;
}
.post .coment-post .ver-respuestas:hover {
  color: #6857b2;
  text-decoration: underline;
}
