.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}


/* VARIABLES */

:root {
  --main-color: #6857B2;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}


/* GRID */

.rowCalendar {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}


/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
  cursor: pointer;
  transition: .15s ease-out;
}

.calendar .header .icon:hover {
  transform: scale(1.75);
  transition: .25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: .75em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .bodyCalendar .cell {
  position: relative;
  height: 5em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}

.calendar .bodyCalendar .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.calendar .bodyCalendar .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #6857B2 0%,#b5a7f1 40%);
  border-image-slice: 1;
}

.calendar .bodyCalendar .selectedDia .number {
  color: var(--main-color) !important;
}

.calendar .bodyCalendar .rowCalendar {
  border-bottom: 1px solid var(--border-color);
}

.calendar .bodyCalendar .rowCalendar:last-child {
  border-bottom: none;
}

.calendar .bodyCalendar .cell:last-child {
  border-right: none;
}

.calendar .bodyCalendar .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: .75em;
  right: .75em;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.54);
}

.calendar .bodyCalendar .disabled {
  color: var(--text-color-light) !important;
  pointer-events: none;
}

.calendar .bodyCalendar .disabled .number {
  color: var(--text-color-light) !important;
}

.calendar .bodyCalendar .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -.2em;
  right: -.05em;
  transition: .25s ease-out;
  letter-spacing: -.07em;
}

.calendar .bodyCalendar .cell:hover .bg, .calendar .bodyCalendar .selected .bg  {
  opacity: 0.05;
  transition: .5s ease-in;
}

.calendar .bodyCalendar .cell:hover .bg, .calendar .bodyCalendar .selectedDia .bg  {
  opacity: 0.05;
  transition: .5s ease-in;
}

.calendar .bodyCalendar .col {
  flex-grow: 0;
  flex-basis: calc(100%/7);
  width: calc(100%/7);
}