body {
  overflow-x: hidden;
  background-color: #edf0f1;
}
a {
  color: #6857b2 !important;
}
.p-0 {
  padding: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.cs-p {
  cursor: pointer;
}
.fz-09 {
  font-size: 0.9rem !important;
}
.ProgressAbsolut{
  position: absolute;
}
.container {
  padding: 1rem;
  padding-top: 2rem;
  position: relative;
  max-width: 460px;
  margin: 0 auto;
}
.container.active-rpr .card.rpr {
  right: 0;
  width: 100%;
  height: auto;
  top: 50px;
  border-radius: 5px;
  min-width: 100%;
  padding: 30px 0 0;
  overflow: hidden;
  background-color: #fff;
}
.container.active-rpr .card.rpr .recuperar {
  position: absolute;
  top: 40px;
  right: 180px;
  box-shadow: none;
  -webkit-transform: scale(10);
  transform: scale(10);
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.container.active-rpr .card.rpr .recuperar i {
  line-height: 0;
}
.container.active-rpr .card.rpr .title,
.container.active-rpr .card.rpr .cont-regis {
  left: 0;
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease;
}
.container.active-alt .card.alt {
  right: 0;
  width: 100%;
  height: auto;
  top: 50px;
  border-radius: 5px;
  min-width: 100%;
  padding: 20px 0 40px;
  overflow: hidden;
  background-color: #fff;
}
.container.active-alt .card.alt .toggle {
  position: absolute;
  top: 40px;
  right: 180px;
  box-shadow: none;
  -webkit-transform: scale(10);
  transform: scale(10);
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.container.active-alt .card.alt .toggle i {
  line-height: 0;
}
.container.active-alt .card.alt .title,
.container.active-alt .card.alt .cont-regis {
  left: 0;
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease;
}
.container.active .card:first-child {
  background: #fff;
  margin: 0 15px;
}
.container.active .card:nth-child(2) {
  background: #f2f2f2;
  margin: 0 10px;
}
.container .card {
  margin: 0;
  border-radius: 5px;
  background: #fff;
  position: relative;
  padding: 10px 0 40px 0;
  transition: 0.3s ease;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.container .card:first-child {
  padding: 0;
  height: 10px;
  background: #fafafa;
  margin: 0 10px;
  border-radius: 5px 5px 0 0;
}
.container .card .logo {
  width: 250px;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 15px;
}
.container .card .logo img {
  width: 100%;
  height: auto;
}
.container .card .title {
  font-weight: 600;
  font-size: 32px;
  z-index: 1;
  position: relative;
  text-align: center;
  padding: 10px 0 10px;
  color: #6857b2;
  margin: 15px 0 30px;
  border-left: 5px solid #6857b2;
}
.container .card .cont-regis {
  position: relative;
  margin: 0 40px 50px;
}
.container .card .cont-regis .form {
  width: 100%;
}
.container .card .cont-regis .form .cont-btn {
  padding-top: 1rem;
  margin-left: 0rem;
}
.container .card .form {
  width: 90%;
  margin: auto;
}
.container .card .form .cont-btn {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.container .card .form .cont-aviso {
  margin-bottom: 1rem;
  padding: 0.6rem;
}
.container .card .form .cont-aviso p {
  color: #063858;
}
.container .card .form .text-confirm {
  font-size: 1.4rem;
}
.container .card .form .g-recaptcha > div {
  width: auto !important;
  height: auto !important;
}
.container .card.alt,
.container .card.rpr {
  padding: 0;
  background: none;
  top: 60px;
  z-index: 10;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  right: -35px;
  position: absolute;
  box-shadow: none;
  transition: 0.3s ease;
}
.container .card.alt .toggle,
.container .card.rpr .toggle,
.container .card.alt .recuperar,
.container .card.rpr .recuperar {
  position: relative;
  background: #fff;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  color: #6857b2;
  text-align: center;
}
.container .card.alt .toggle i,
.container .card.rpr .toggle i,
.container .card.alt .recuperar i,
.container .card.rpr .recuperar i {
  font-size: 45px;
  line-height: 71px;
  cursor: pointer;
}
.container .card.alt .title,
.container .card.rpr .title,
.container .card.alt .cont-regis,
.container .card.rpr .cont-regis {
  left: 100px;
  opacity: 0;
  visibility: hidden;
}
.container .card.alt .title,
.container .card.rpr .title {
  position: relative;
  color: #6857b2;
  border-color: #6857b2;
}
.container .card.alt .title .close,
.container .card.rpr .title .close {
  top: 0;
  font-weight: 400;
  font-size: 58px;
  right: 30px;
  display: inline;
  position: absolute;
  color: #6857b2;
  cursor: pointer;
}
.container .regis .tipo-user {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}
.container .icon {
  margin-left: -7px;
  margin-top: 0.5rem;
  padding-top: 0.2rem;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #696464;
  border-radius: 50%;
  border: 2px solid #6857b2;
}
