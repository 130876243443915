.content-direct {
  position: relative;
}
.content-direct .content-message {
  width: 100%;
  position: relative;
  height: calc(100vh - 88px);
}
.content-direct .content-message .aviso-seleccion {
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.content-direct .content-message .aviso-seleccion span:first-child {
  font-size: 17px;
  font-weight: 600;
}
.content-direct .content-message .aviso-seleccion span:nth-child(2) {
  font-size: 15px;
  padding: 12px;
}
.content-direct .content-message .header-message ul {
  padding-top: 0;
  padding-bottom: 0;
}
.content-direct .content-message .header-message ul li p {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.content-direct .content-message .body-message {
  z-index: 0;
  flex-grow: 1;
  padding: 12px;
  display: flex;
  flex-shrink: 0;
  flex-basis: auto;
  overflow-y: auto;
  display: -moz-box;
  position: relative;
  overflow-x: hidden;
  -webkit-box-flex: 1;
  -ms-flex-negative: 0;
  display: -ms-flexbox;
  -webkit-box-pack: end;
  flex-direction: column;
  -ms-flex-align: stretch;
  -ms-flex-direction: column;
  height: calc(100% - 156px);
  -ms-flex-preferred-size: auto;
}
.content-direct .content-message .body-message .massage {
  bottom: 0;
}
.content-direct .content-message .body-message .massage .msj {
  width: auto;
  min-width: 20%;
  max-width: 90%;
  padding: 12px;
  border-radius: 15px;
  margin-bottom: 1rem;
  background-color: #edf0f1;
}
.content-direct .content-message .body-message .massage .msj .msj-header span {
  display: inline-block;
}
.content-direct .content-message .body-message .massage .msj .msj-boddy span:last-child {
  text-align: right;
  font-size: 11px;
}
.content-direct .content-message .body-message .massage .msj.right {
  float: right;
  border-bottom-right-radius: 0px;
}
.content-direct .content-message .body-message .massage .msj.left {
  float: left;
  border-bottom-left-radius: 0px;
}
.content-direct .content-message .body-message .massage .msj.msj-description {
  max-width: 100%;
}
.content-direct .content-message .body-message .massage .msj.msj-description .msj-boddy span {
  text-align: justify;
  font-size: 12px;
}
.content-direct .content-message .body-message .cont-progres {
  top: 20px;
  left: 50%;
  right: 50%;
  margin-left: auto;
  position: absolute;
  margin-right: auto;
}
.content-direct .content-message .footer-message {
  bottom: 0;
  width: 100%;
  height: 90px;
  display: flex;
  padding: 0 12px;
  position: absolute;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  border-top: 1px solid rgba(0,0,0,0.12);
}
.content-direct .content-message .footer-message .cont-imput {
  padding: 6px 10px;
  border-radius: 18px;
  display: inline-block;
  width: calc(100% - 46px);
  border: 1px solid #b4b9bb;
  background-color: #edf0f1;
}
.content-direct .content-message .footer-message .cont-imput .text-area {
  width: calc(100% - 35px);
}
.content-direct .content-message .footer-message .cont-imput .icon-emoji {
  float: right;
}
.content-direct .content-message .footer-message .cont-imput .icon-emoji .btn-icon-emoji {
  padding: 0;
  min-width: 30px;
}
.content-direct .content-message .footer-message .cont-imput .icon-emoji .btn-icon-emoji:hover {
  background-color: transparent !important;
}
.content-direct .content-message.content-message-modal {
  width: 100% !important;
  height: calc(100vh - 200px);
}
.content-direct .content-list-message {
  width: 100%;
}
.content-direct .content-list-message .paper-buscar .title-paper button {
  float: right;
  padding: 0;
}
.content-direct .content-list-message .paper-buscar .title-paper button span svg {
  font-size: 20px;
}
.content-direct .content-list-message .list-mesages {
  overflow: auto;
  height: calc(100vh - 163px);
}
.content-direct .content-list-message .list-mesages ul {
  padding-top: 0;
}
.content-direct .content-list-message .list-mesages .li-message {
  padding-left: 14px;
  padding-right: 14px;
}
.content-direct .content-list-message .list-mesages .li-message .fech-message {
  font-size: 12px;
  font-weight: 600;
  color: rgba(0,0,0,0.54);
}
.content-direct .content-list-message .list-mesages .li-message .description-message {
  color: rgba(0,0,0,0.54);
}
.content-direct .content-list-message .list-mesages .notify-direct {
  z-index: 1;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
  border: 5px solid #6857b2;
}
.dialog-direct .search-seguidor {
  padding: 3px 12px;
  display: flex;
  background-color: #efefef;
}
.dialog-direct .select {
  overflow: auto;
  max-height: 90px;
  padding: 12px 12px 8px;
}
.dialog-direct .select div {
  margin: 3px;
}
.dialog-direct .nom-grup {
  padding: 3px 12px;
  background-color: #efefef;
}
.dialog-direct .list-seguidores {
  overflow: auto;
  max-height: 200px;
}
.dialog-direct .list-seguidores ul {
  padding-top: 0;
}
.dialog-direct .list-seguidores ul div li {
  padding-left: 12px;
  padding-right: 12px;
}
@media screen and (min-width: 600px) {
  .content-direct {
    display: flex;
  }
  .content-direct .content-message {
    width: calc(100% - 280px);
  }
  .content-direct .content-list-message {
    width: 280px;
    padding: 0 12px;
  }
}
@media screen and (min-width: 960px) {
  .content-direct .content-message {
    width: calc(100% - 350px);
  }
  .content-direct .content-list-message {
    width: 350px;
  }
}
