.body .portada {
  width: 100%;
  height: auto;
  background: #fff;
  border-bottom: 1px solid rgba(216,215,220,0.71);
}
.body .portada .cont-foto-portada {
  width: 100%;
  height: 140px;
  position: relative;
  background-color: #595955;
}
.body .portada .cont-foto-portada .img-portada {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.body .portada .cont-foto-portada .img-portada img {
  width: 100%;
  height: 100%;
}
.body .portada .cont-bar-action {
  width: 100%;
/*Movil*/
}
.body .portada .cont-bar-action .img-and-btn-follow {
  height: 50px;
  position: relative;
}
.body .portada .cont-bar-action .img-and-btn-follow .cont-img-perfil {
  top: -75px;
  padding-left: 1rem;
  position: absolute;
}
.body .portada .cont-bar-action .name-user {
  font-size: 1.3rem;
  font-weight: bold;
}
.body .portada .cont-bar-action .time-regis {
  font-size: 0.7rem;
}
.body .portada .cont-bar-action .extracto {
  overflow: hidden;
  max-height: 100px;
}
.body .portada .cont-bar-action .br-top {
  border-top: 1px solid rgba(216,215,220,0.71);
}
.body .portada .cont-bar-action .br-right {
  border-right: 1px solid rgba(216,215,220,0.71);
}
.body .portada .cont-bar-action .sifras {
  font-size: 1.2rem;
}
.body .portada .cont-bar-action .cont-bloques {
  display: flex;
}
.body .portada .cont-bar-action .cont-bloques .bloque {
  width: 20%;
  text-align: center;
}
.body .paperSeguidores .cont-info-header {
  display: flex;
}
.body .paperSeguidores .cont-info-header .cont-text {
  width: calc(100% - 60px);
  padding: 0.5rem;
}
.body .paperSeguidores .cont-info-header .cont-text p {
  color: #595955;
}
.body .paperSeguidores .cont-info-header .cont-text span {
  font-size: 0.78rem;
  color: #6857b2;
}
.body .paperSeguidores .cont-action p {
  margin-top: 0.3rem;
  color: rgba(0,0,0,0.54);
}
.contRecorteImg {
  position: relative;
}
.contRecorteImg .ReactCrop div:first-child img {
  max-height: fit-content;
}
.max-200 {
  max-height: 200px;
  overflow: hidden;
}
@media screen and (min-width: 960px) {
  .body .portada {
    position: relative;
  }
  .body .portada .cont-foto-portada {
    height: 280px;
  }
  .body .portada .cont-bar-action {
    height: 50px;
  }
  .body .portada .cont-bar-action .img-and-btn-follow .cont-img-perfil {
    top: -155px;
  }
  .body .barLeftPerfil {
    float: left;
    width: 400px;
  }
  .body .barLeftPerfil .extracto {
    overflow: hidden;
    max-height: 100px;
  }
  .body .contPerfil {
    margin-left: 400px;
    width: calc(100% - 400px);
  }
}
