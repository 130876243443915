/*   custmizar css aca para sm   */
.fontMenu {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.fontMenu div span {
  font-size: 0.875rem;
}
.active-menu {
  border-radius: 2px !important;
  background-color: #f5f6f7 !important;
  border: 1px solid #dddfe2 !important;
}
.active-menu:after {
  content: " ";
  width: 8px;
  right: 8px;
  height: 8px;
  position: absolute;
  border-radius: 50%;
  background-color: #6857b2;
}
.list-item-menu {
  height: calc(100vh - 115px);
  overflow-x: hidden;
  overflow-y: auto;
}
.cont-progres {
  width: 100px;
  height: auto;
  text-align: center;
  margin: 0.5rem auto;
}
.cont-progres div {
  width: 50px;
  height: 50px;
}
.cont-progres span {
  color: #6857b2;
}
.paper {
  padding: 12px;
}
.paper .title-paper {
  margin-bottom: 0.5rem;
}
.paper .title-paper span {
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  color: #6857b2;
  display: inline-block;
}
.paper .title-paper .color-gris {
  color: #7a7a77;
  font-weight: normal;
}
.paper .title-paper .nombre-user {
  font-size: 1.3rem;
  margin-bottom: 0.3rem;
}
.paper .title-paper .time-in-platafor {
  font-size: 0.75rem;
}
.paper .title-paper.interconsulta-title {
  text-align: center;
}
.paper .title-paper.interconsulta-title span {
  font-size: 19px;
  line-height: initial;
}
.paper .p-12 {
  padding: 12px;
}
.paper .header-hilo-inciden {
  padding: 12px;
  border-bottom: 2px solid #e0e0e0;
  height: 70px;
}
.paper .header-hilo-inciden span {
  float: left;
  margin-top: 15px;
  margin-left: 10px;
  font-weight: bold;
}
.paper .header-hilo-inciden figure {
  float: left;
  width: 50px;
  height: 50px;
  overflow: hidden;
  padding-top: 0.3rem;
  border-radius: 50%;
  text-align: center;
  margin: 0 !important;
  background-color: #fff;
  border: 1px solid #999;
}
.paper .header-hilo-inciden figure img {
  width: 40px;
  height: 40px;
}
.paper .header-hilo-inciden .cont-img-avatar {
  float: left;
}
.paper .header-hilo-inciden .cont-img-avatar .img-avatar {
  width: 100%;
  height: 100%;
}
.paper .body-hilo-inciden {
  clear: both;
  padding: 12px;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.paper .body-hilo-inciden .msj {
  width: auto;
  min-width: 20%;
  max-width: 90%;
  padding: 12px;
  border-radius: 5px;
  margin-bottom: 1rem;
  background-color: #edf0f1;
}
.paper .body-hilo-inciden .msj .msj-header span {
  display: inline-block;
}
.paper .body-hilo-inciden .msj .msj-boddy span:last-child {
  text-align: right;
  font-size: 11px;
}
.paper .body-hilo-inciden .msj.right {
  float: right;
}
.paper .body-hilo-inciden .msj.left {
  float: left;
}
.paper .body-hilo-inciden .msj.msj-description {
  max-width: 100%;
}
.paper .body-hilo-inciden .msj.msj-description .msj-boddy span {
  text-align: justify;
  font-size: 12px;
}
.paper .action-hilo-inciden {
  padding: 12px;
  border-top: 2px solid #e0e0e0;
}
.paper .clear {
  clear: both;
}
.paper-buscar .title-paper {
  padding: 6px 12px;
  background-color: #f5f6f7;
}
.paper-buscar .title-paper span {
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  color: #6857b2;
  display: inline-block;
}
.paper-buscar .cont-paper {
  padding: 6px 12px;
}
.paper-buscar .cont-paper .fz15 input {
  font-size: 15px;
}
.paper-historia {
  display: flex;
  overflow: hidden;
}
.paper-historia .img-paper {
  width: 20%;
}
.paper-historia .img-paper img {
  width: 100%;
  height: 100%;
}
.paper-historia .cont-paper {
  width: 80%;
  padding: 12px;
}
.paper-bar-left {
  padding: 0.5rem;
}
.paper-bar-left .title-paper {
  margin-bottom: 0.5rem;
}
.paper-bar-left .title-paper span {
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  color: #6857b2;
  display: inline-block;
}
.paper-bar-left .crear-Histo .info-paciente {
  width: 60%;
  display: inline-block;
}
.paper-bar-left .crear-Histo .action {
  width: 35%;
  display: inline-block;
}
.paper-bar-left .crear-Histo hr {
  margin: 5px 0;
}
.paper-bar-left .cant-follower {
  margin-top: 0.5rem;
}
.paper-bar-left .cant-follower h2 {
  font-size: 20px;
  color: #6857b2;
}
.paper-bar-left .list-sugerencias {
  padding: 0;
}
.paper-bar-left .list-sugerencias li {
  padding-left: 0;
  padding-right: 0;
}
.paper-bar-left .list-sugerencias li div p .especialidades {
  font-size: 12px;
  color: #6857b2;
}
.paper-bar-left .list-sugerencias li div p button {
  margin-top: 0.5rem;
  margin-right: 0.8rem;
  text-transform: inherit !important;
}
.breadcrumbs {
  display: flex;
}
.edit-html .ql-container {
  background: #fefcfc;
}
.edit-html .ql-snow.ql-toolbar {
  display: block;
  background: #f5f6f7;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.edit-html .ql-bubble .ql-editor {
  border: 1px solid #ccc;
  border-radius: 0.5em;
}
.edit-html .ql-editor {
  min-height: 18em;
}
.edit-html .themeSwitcher {
  margin-top: 0.5em;
  font-size: small;
}
.barRight {
  top: 0;
  right: 0;
  width: 270px;
  padding: 12px;
  height: 100vh;
  z-index: 1200;
  position: fixed;
  background-color: #edf0f1;
}
.barRight .cont-fixed {
  width: 100%;
}
.barRight.dsp-none {
  display: none !important;
}
.capa-barRight {
  top: 0;
  width: 100%;
  z-index: 1100;
  height: 100vh;
  position: fixed;
  touch-action: none;
  background-color: rgba(0,0,0,0.5);
  -webkit-tap-highlight-color: transparent;
}
.crearIncidencia {
  top: 6rem;
  width: 94%;
  z-index: 1199;
  position: absolute;
  background: #fff;
  border-radius: 4px 4px 0 0;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
}
.crearIncidencia .title-new-inciden {
  height: 40px;
  padding: 10px;
  border-radius: 4px 4px 0 0;
  background-color: #595955;
}
.crearIncidencia .title-new-inciden span {
  float: left;
  color: #fff;
  font-weight: bold;
}
.crearIncidencia .content-inciden {
  padding: 10px;
  height: 50vh;
}
.crearIncidencia .action-inciden {
  padding: 10px;
}
.contExpandPagos div:first-child {
  width: 100%;
}
@media screen and (min-width: 600px) {
  .body .content.econsultorio .cont-citas {
    max-width: 300px;
  }
}
/*   custmizar css aca para md   */
@media screen and (min-width: 960px) {
  .body {
    position: relative;
    min-height: calc(100vh - 64px);
  }
  .body .contMenu {
    min-height: calc(100vh - 64px);
    width: 180px;
    position: fixed;
  }
  .body .barRight {
    width: 290px;
    float: right;
    padding: 12px 0;
    position: initial;
    height: calc(100vh - 64px);
    transform: inherit !important;
  }
  .body .barRight .cont-fixed {
    width: 270px;
    position: fixed;
  }
  .body .barRight.dsp-none {
    display: block !important;
  }
  .body .paper-historia .img-paper {
    width: 30%;
  }
  .body .content {
    margin-left: 180px;
    width: calc(100% - 470px);
  }
  .body .content.sin-bar {
    width: calc(100% - 180px);
  }
  .body .crearIncidencia {
    top: auto;
    bottom: 0px;
    right: 20px;
    width: 650px;
  }
}
/*   custmizar css aca para LG y LX   */
